<template>
    <div>
        <!--    <div class="filters-bar__primary">-->
        <!--      <div class="filters-bar__title">-->
        <!--        <h1>{{ goods.cat[cat].name }}</h1>-->
        <!--      </div>-->
        <!--    </div>-->
        <div class="paginated-list category__grid">
            <div id="list-item-0" class="-remote">
                <div class="campaign-plp-push" @click="butShop(goods.cat[cat].url)">
                    <img :src="goods.cat[cat].img" alt="">
                </div>
                <div class="campaign-plp-push__details">
                    <p>Pink-Light</p>
                    <p>MY PLエッセンスシリーズ</p>
                    <hr>
                    <p>潤いと透明感を与え、</p>
                    <p>柔らかく滑らかな肌の質感を演出します。</p>
                </div>
            </div>
            <ul class="list">
<!--                <li id="list-item-0" class="-remote">-->
<!--                    <div class="campaign-plp-push" @click="butShop(goods.cat[cat].url)">-->
<!--                        <img :src="goods.cat[cat].img" alt="">-->
<!--                    </div>-->
<!--                    <div class="campaign-plp-push__details">-->
<!--                        <p>Pink-Light</p>-->
<!--                        <p>MY PLエッセンスシリーズ</p>-->
<!--                        <hr>-->
<!--                        <p>潤いと透明感を与え、</p>-->
<!--                        <p>柔らかく滑らかな肌の質感を演出します。</p>-->
<!--                    </div>-->
<!--                </li>-->
                <li class="" v-for="(item,index) in goods.list[cat]" :key="index">
                    <div class="product-card -has-mini-slider -compact-large -default-variant">
                        <div class="product-card__wrap" @click="butShop(item.url)">
                            <div class="product-card__info-wrapper">
                                <p>{{ item.name }}</p>
                                <p>{{ item.specs }}</p>
                                <p>{{ item.price }}</p>
                            </div>
                            <div class="product-card__media -grey-background">
                                <img :src="item.img" alt="">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cat:0, //  0：高光  1：粉光
            goods:{
                cat:{
                    0:{
                        name:'高光系列',
                        url:'https://shop.wisdomjp.co.jp/zh/collections/my-hl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA',
                        img:require("@/assets/images/product_banner_1.jpg")
                    },
                    1:{
                        name:'粉光系列',
                        url:'https://shop.wisdomjp.co.jp/zh/collections/my-pl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA',
                        img:require("@/assets/images/product_banner_2.jpg")
                    }
                },
                list:{
                    0:[
                        {
                            name:'MY HLエッセンスローション',
                            url:'https://shop.wisdomjp.co.jp/zh/products/myhlessence',
                            specs:'120mL/本',
                            price:'￥ 298.00',
                            img:require("@/assets/images/fenguang/shui.png")
                        },{
                            name:'MY HLエッセンスミルク',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-hl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%83%9F%E3%83%AB%E3%82%AF',
                            specs:'100mL/本',
                            price:'￥ 328.00',
                            img:require("@/assets/images/gaoguang/ruye.png")
                        },{
                            name:'MY HLエッセンスクリーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/myhlessencecream50g',
                            specs:'50g/箱',
                            price:'￥ 388.00',
                            img:require("@/assets/images/gaoguang/mianshuang.png")
                        },{
                            name:'MY HLエッセンスアイクリーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-hl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%A2%E3%82%A4%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%A0',
                            specs:'15g/箱',
                            price:'￥ 328.00',
                            img:require("@/assets/images/gaoguang/yanshuang.png")
                        },{
                            name:'MY HL洗顔フォーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-hl%E6%B4%97%E9%A1%94%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0',
                            specs:'125g/本',
                            price:'￥ 168.00',
                            img:require("@/assets/images/gaoguang/jiemian.png")
                        },{
                            name:'MY HL日焼け止め',
                            url:'https://shop.wisdomjp.co.jp/zh/products/myhlsunscreen60g',
                            specs:'50g/本',
                            price:'￥ 138.00',
                            img:require("@/assets/images/gaoguang/fangshai.png")
                        }
                    ],
                    1:[
                        {
                            name:'MY PL洗顔フォーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-pl%E6%B4%97%E9%A1%94%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0',
                            specs:'125g/本',
                            price:'￥148.00',
                            img:require("@/assets/images/fenguang/jiemian.png")
                        },{
                            name:'MY PLエッセンスローション',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-pl-%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%83%9F%E3%83%AB%E3%82%AF',
                            specs:'120mL/本',
                            price:'￥268.00',
                            img:require("@/assets/images/fenguang/ruye.png")
                        },{
                            name:'MY PLエッセンスミルク',
                            url:'https://shop.wisdomjp.co.jp/zh/products/myplessence120ml',
                            specs:'100mL/本',
                            price:'￥298.00',
                            img:require("@/assets/images/fenguang/shui.png")
                        },{
                            name:'MY PLエッセンスアイクリーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/my-pl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%A2%E3%82%A4%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%A0',
                            specs:'15g/箱',
                            price:'￥298.00',
                            img:require("@/assets/images/fenguang/yanshuang.png")
                        },{
                            name:'MY PLエッセンスクリーム',
                            url:'https://shop.wisdomjp.co.jp/zh/products/myplessencecream50g',
                            specs:'50g/箱',
                            price:'￥358.00',
                            img:require("@/assets/images/fenguang/mianshuang.png")
                        }
                    ],
                }
            }
        }
    },
    methods: {
        butShop(url){
            window.location.href = url
        }
    },
    mounted(){

    },
    created(){
        if(this.$route.query.cat){
            this.cat = this.$route.query.cat
        }
    }
}
</script>

<style scoped>
.paginated-list{
    margin-top: 116px;
}
.filters-bar__primary{
    padding-left: 3.3333333333vw;
    padding-right: 3.3333333333vw;
    min-height: 4.5rem;
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 116px;
    box-shadow: inset 0 -1px 0 0 #eae8e4;
}
.list{
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 25% 25% 25%;
    justify-content: space-between;
}
.filters-bar__title{
    font-size: 0.9rem;
    color: #19110b;
}
.category__grid{
    border-right: 1px solid #f5f4f2;
    border-left: 1px solid #f5f4f2;
    padding-left: 3.3333333333vw;
    padding-right: 3.3333333333vw;
    box-sizing: border-box;
    letter-spacing: 0;
    line-height: normal;
    font-style: normal;
}
.-remote{
    width: calc(1920px - 6.3vw);
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 4rem;
}
.list>li:nth-child{
    width: auto;
}
.list>li{
    margin: 0 0 2rem;
}
.campaign-plp-push{
    display: block;
    width: calc(66.667% - 0.5rem);
    cursor:pointer;
}
.campaign-plp-push img{
    width: 100%;
    height: auto;
}
.campaign-plp-push__details{
    padding-left: 3.125vw;
    padding-right: 3.125vw;
    width: 33.333%;
    box-sizing: border-box;
    display: block;
}
.campaign-plp-push__details p{
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.4rem;
    margin: 0;
    color: #59514f;
}
.product-card__wrap {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    flex-direction: column-reverse;
    cursor:pointer;
}
.product-card__info-wrapper{
    padding: 10px;
}
.product-card__info-wrapper p{
    margin: 0;
    padding: 0 !important;
    line-height: 20px;
    color: #838383;
    font-size: 0.8rem;
}
.product-card__media{
    background-color: #dcdcde;
    overflow: hidden;
    display: block;
    position: relative;
}
.product-card__media img{
    width: 100%;
    height: auto;
}
</style>
