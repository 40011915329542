export function indexConfig(){
    let url = window.location.href;
    if(url.indexOf('index') !== -1){
        let header__container = document.getElementById('header__container');
        header__container.onmouseover = showBGColor;
        header__container.onmouseout = clearBGColor;
        setTimeout(bannerTop, 500)
        windowAddMouseWheel();
    }
}
export function notIndexConfig(){
    let div = document.getElementById('header__container');
    div.onmouseover = null;
    div.onmouseout = null;
    document.removeEventListener('DOMMouseScroll', scrollFunc);
    window.onmousewheel = document.onmousewheel = null;
    div.setAttribute('style', 'box-shadow:inset 0 -1px 0 0 #eae8e4;background:#fff;')
    document.querySelector('.logo__wrap img').setAttribute('src', require('@/assets/images/header_logo.png'))
    document.querySelector('.header__utility-item img').setAttribute('src', require('@/assets/images/logo.png'))
    // document.querySelector('.-my a').style.color = '#000'
    // document.querySelector('.-cart a').style.color = '#000'
    document.querySelector('.-lang a').style.color = '#000'
    let liColor = document.querySelectorAll('.header__main-nav .header-main-nav li button span');
    for (let i = 0; i < liColor.length; i++) {
        liColor[i].style.color = '#000'
    }
}
function showBGColor() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop <= 116) {
        let div = document.getElementById('header__container');
        div.setAttribute('style', 'box-shadow:inset 0 -1px 0 0 #eae8e4;background:#fff;')
        document.querySelector('.logo__wrap img').setAttribute('src', require('@/assets/images/header_logo.png'))
        document.querySelector('.header__utility-item img').setAttribute('src', require('@/assets/images/logo.png'))
        // document.querySelector('.-my a').style.color = '#000'
        document.querySelector('.-lang a').style.color = '#000'
        // document.querySelector('.-cart a').style.color = '#000'
        let liColor = document.querySelectorAll('.header__main-nav .header-main-nav li button span');
        for (let i = 0; i < liColor.length; i++) {
            liColor[i].style.color = '#000'
        }
    }
}

function clearBGColor() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop <= 116) {
        let div = document.getElementById('header__container');
        div.setAttribute('style', 'box-shadow:inset 0 -1px 0 0 rgba(0,0,0,0);background:rgba(0,0,0,0)')
        document.querySelector('.logo__wrap img').setAttribute('src', require('@/assets/images/logo_2.png'))
        document.querySelector('.header__utility-item img').setAttribute('src', require('@/assets/images/logo_1.png'))
        // document.querySelector('.-my a').style.color = '#fff'
        // document.querySelector('.-cart a').style.color = '#fff'
        document.querySelector('.-lang a').style.color = '#fff'
        let liColor = document.querySelectorAll('.header__main-nav .header-main-nav li button span');
        for (let i = 0; i < liColor.length; i++) {
            liColor[i].style.color = '#fff'
        }
    }
}

function bannerTop() {
    let div = document.querySelector('.header__main')
    let header__container = document.querySelector('.header__container')
    header__container.setAttribute('style', 'box-shadow:inset 0 -1px 0 0 rgba(0,0,0,0);background:rgba(0,0,0,0)')
    div.style.transform = 'translateY(0px)'
    div.style.position = 'unset'
    document.querySelector('.logo__wrap img').setAttribute('src', require('@/assets/images/logo_2.png'))
    document.querySelector('.header__utility-item img').setAttribute('src', require('@/assets/images/logo_1.png'))
    // document.querySelector('.-my a').style.color = '#fff'
    // document.querySelector('.-cart a').style.color = '#fff'
    document.querySelector('.-lang a').style.color = '#fff'
    let liColor = document.querySelectorAll('.header__main-nav .header-main-nav li button span');
    for (let i = 0; i < liColor.length; i++) {
        liColor[i].style.color = '#fff'
    }
}

function bannerBottom() {
    let div = document.querySelector('.header__main')
    let header__container = document.querySelector('.header__container')
    document.querySelector('.logo__wrap img').setAttribute('src', require('@/assets/images/header_logo.png'))
    document.querySelector('.header__utility-item img').setAttribute('src', require('@/assets/images/logo.png'))
    header__container.setAttribute('style', 'box-shadow:inset 0 -1px 0 0 #eae8e4;background:#fff;')
    div.style.transform = 'translateY(0px)'
    div.style.position = 'fixed'
    // document.querySelector('.-my a').style.color = '#000'
    // document.querySelector('.-cart a').style.color = '#000'
    document.querySelector('.-lang a').style.color = '#000'
    let liColor = document.querySelectorAll('.header__main-nav .header-main-nav li button span');
    for (let i = 0; i < liColor.length; i++) {
        liColor[i].style.color = '#000'
    }
}

function scrollFunc(e){
    e = e || window.event;
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let div = document.querySelector('.header__main')
    if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) { //当滑轮向上滚动时
            if (scrollTop <= 116) {
                bannerTop()
            } else {
                bannerBottom()
            }
        }
        if (e.wheelDelta < 0) { //当滑轮向下滚动时
            if (scrollTop > 116) {
                div.style.top = '0px';
                div.style.transform = 'translateY(-116px)';
            } else {
                bannerTop()
            }
        }
    }
}

function windowAddMouseWheel() {
    //给页面绑定滑轮滚动事件
    if (document.addEventListener) {
        document.addEventListener('DOMMouseScroll', scrollFunc, false);
    }
    //滚动滑轮触发scrollFunc方法
    window.onmousewheel = document.onmousewheel = scrollFunc;
}














