<template>
    <div class="brief">
        <div class="main">
            <div class="banner">
                <img src="@/assets/images/brief_banner.jpg" alt="">
            </div>
            <p class="brief_title">会社プロフィール</p>
            <div class="brief_main">
                <ul>
                    <li>
                        <h2 class="title">商号</h2>
                        <div class="content">
                            <div class="box">
                                <span>株式会社ウィズダム</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">設立</h2>
                        <div class="content">
                            <div class="box">
                                <span>平成15年4月28日</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">資本金</h2>
                        <div class="content">
                            <div class="box">
                                <span>6,400万円</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">代表取締役</h2>
                        <div class="content">
                            <div class="box">
                                <span>杼木浩</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">所在地</h2>
                        <div class="content">
                            <div class="box">
                                <span>本社：〒169-0051東京都新宿区西早稲田一丁目9番35-302号</span>
                            </div>
                            <div class="box">
                                <span>支社：〒103-0002東京都中央区日本橋馬喰町1-9-1 The CORNER日本橋east 4F</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">連絡先</h2>
                        <div class="content">
                            <div class="box"><span>TEL：03-6820-0668 / FAX：03-6820-0668</span></div>
                            <div class="box"><span>info@wisdomjp.co.jp</span></div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">事業内容</h2>
                        <div class="content ">
                            <div class="box"><span>基礎化粧品、医薬部外品</span></div>
                            <div class="box"><span>日用品雑貨、美容器等の商品企画開発</span></div>
                            <div class="box"><span>OEM受託製造、販売各種、及び上記に関わるコンサルティング</span></div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">許可</h2>
                        <div class="content">
                            <div class="box"><span>化粧品製造販売業許可</span></div>
                            <div class="box"><span>医薬部外品製造販売業許可</span></div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">所属団体</h2>
                        <div class="content">
                            <div class="box"><span>日本化粧品工業連合会正会員</span></div>
                        </div>
                    </li>
                    <li>
                        <h2 class="title">取引銀行</h2>
                        <div class="content">
                            <div class="box"><span>みずほ銀行</span></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="banner map" style="margin-top: 2rem;padding-bottom: 4rem">
                <img :src="require('@/assets/images/' + lang + '_map.jpg')" alt="">
            </div>
        </div>

    </div>
</template>

<script>
import {getCookieVal} from "@/assets/js/lang";

export default {
    data(){
        return{
            screenWidth:null,
            lang:'zh'
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/brief'){
                    this.$router.replace('/m/brief')
                }
            }else{
                if(this.$route.path !== '/brief'){
                    this.$router.replace('/brief')
                }
            }
        }
    },
    mounted() {
        this.lang = getCookieVal('lang');
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style scoped>
.brief{
    width: 100%;
    background: #f5f4f2;
}
.main{
    width: 90%;
    margin: 0 auto;
    padding-top: 156px;
}
.banner{
    width: 100%;
}
.banner img{
    width: 100%;
    height: auto;
}
.brief_title{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.brief_main{
    width: 900px;
    margin: 0 auto 50px;
    color: #665b56;
}
.brief_main ul li{
    display: flex;
    margin-bottom: 30px;
}
.brief_main ul li h2{
    width: 287px;
    font-size: 19px;
}
.brief_main ul li .content{
    width: 600px;
    line-height: 31px;
}
/*.brief_main ul li{*/
/*    float: left;*/
/*    width: calc(33.10% - 10%);*/
/*    background: #ffffff;*/
/*    padding: 30px 5%;*/
/*    margin: 0 1px;*/
/*    margin-bottom: 7px;*/
/*}*/
/*.brief_main .icon{*/
/*    position: relative;*/
/*    top: 4px;*/
/*    width: 20px;*/
/*    height: auto;*/
/*}*/
/*.brief_main .title{*/
/*    height: 62px;*/
/*}*/
/*.brief_main .content{*/
/*    height: 108px;*/
/*    position: relative;*/
/*}*/
/*.brief_main .box{*/
/*    height: 36px;*/
/*    line-height: 36px;*/
/*    border: 1px solid #e8e8e8;*/
/*    text-align: center;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    overflow: hidden;*/
/*}*/


.big_box{
    height: 135px !important;
}
</style>
