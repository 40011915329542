import $ from './jquery.min'
let dict = {};
$(function(){
    registerWords();
    let cookies = getCookieVal("lang")
    if(cookies==null){
        setLanguage("jap");
    }else{
        setLanguage(cookies);
    }
    $('.brand_li').hover(function(){
        $('.brand').css('display','block')
    },function(){
        $('.brand').css('display','none')
    })
})

export function getCookieVal(name) {
    var items = document.cookie.split(";");
    for (let i in items) {
        let cookie = $.trim(items[i]);
        let eqIdx = cookie.indexOf("=");
        let key = cookie.substring(0, eqIdx);
        if (name == $.trim(key)) {
            return $.trim(cookie.substring(eqIdx+1));
        }
    }
    return null;
}

export function setLanguage(lang) {
    setCookie("lang=" + lang + "; path=/;");
    translate();
}

function setCookie(cookie) {
    document.cookie = cookie;
}

function translate() {
    loadDict();
    let lang = getCookieVal('lang')
    /* 顶部 */
    let header = __tr('header');
    $('.lv-header__lock_lang').text(header.lang)
    $('.header-main-nav-panel__trigger span').each(function(i){
        $(this).text(header.nav[i])
    })

    /* index */
    let index = __tr('index');
    $('.choose span').text(index.button[0])
    $('.website span').text(index.button[1])
    $('.homepage-push button').text(index.button[1])

    /* 底部 */
    let footer = __tr('footer');
    $('.lv-list .lv-list_li a').each(function(i){
        $(this).text(footer.list[i])
    })

    /* OEM */
    let about = __tr('about');
    $('.m-develop .content__title').html(about.title[0])
    $('.m-develop .content__title_text span:eq(0)').text(about.title[1])
    $('.m-develop .content__title_text span:eq(1)').text(about.title[2])
    $('.m-develop .process_title').text(about.omeTitle)
    let aboutItem = '';
    for(let i = 0;i < about.ome.length;i++){
        aboutItem += '<li class="process__item" key="'+i+'">\n' +
            '                    <div class="process__item__title">\n' +
            '                        <i></i>\n' +
            '                        '+about.ome[i].title+'\n' +
            '                    </div>\n' +
            '                    <div class="process__item__text">'+about.ome[i].content+'</div>\n' +
            '                </li>'
    }
    $('.m-develop .process').html(aboutItem)
    $('.develop_main_p3').html(about.background)
    /* brief */
    let brief = __tr('brief');
    for(let i = 0;i < brief.briefList.length;i++){
        $('.homepage__ul__item .title:eq('+i+')').text(brief.briefList[i].title)
        for(let j = 0;j < brief.briefList[i].content.length;j++){
            $('.item__content:eq('+i+') span:eq('+j+')').text(brief.briefList[i].content[j])
        }
    }

    /* legal-privacy */
    let legalPrivacy = __tr('legalPrivacy');
    let legalHtml = '';
    $.each(legalPrivacy.content,function(key,value){
        legalHtml += '<div id="tab0-Chapter'+key+'" class="tabChapterContainer">\n' +
            '                <div id="tab0-ChapterTitle'+key+'" class="tabChapterTitleContainer">\n' +
            '                    <button class="tabChapterTitle exp_title onlyAS bound" data-target="tab0-ChapterDesc'+key+'" data-group="legalPrivacyChapter-collapse" aria-expanded="false" data-binded="true">\n' +
            '                        <h3>'+value.title+'</h3>\n' +
            '                    </button>\n' +
            '                </div>\n' +
            '                <div id="tab0-ChapterDesc'+key+'" class="tabChapterDescription legalPrivacyPanels legalPrivacyChapter-collapse" style="display: none;">\n' +
            '                    <p>'+value.introduction+'</p>\n' +
            '                </div>\n' +
            '            </div>'
        let Chapter = '.tab0-Chapter'+key
    })
    $('.legalPrivacyTabsContainer').html(legalHtml)


    $('.tabChapterTitle').click(function(){
        let ariaExpanded = $(this).attr('aria-expanded')
        if(ariaExpanded === 'false'){
            $('.tabChapterTitle').removeClass('target-on');
            $('.tabChapterTitle').attr('aria-expanded',false);
            $('.tabChapterTitle').parent().next().css('display','none');
            $(this).addClass('target-on');
            $(this).attr('aria-expanded',true);
            $(this).parent().next().css('display','block');
        }else{
            $(this).removeClass('target-on');
            $(this).attr('aria-expanded',false);
            $(this).parent().next().css('display','none');
        }
    })

}

function __tr(src) {
    return (dict[src] || src);
}

function loadDict() {
    let lang = (getCookieVal("lang") || "jap");
    dict = require('./'+ lang +'.json')
}


function registerWords() {
    $("[lang]").each(function() {
        switch (this.tagName.toLowerCase()) {
            case "input":
                $(this).attr("lang", $(this).val());
                break;
            default:
                $(this).attr("lang", $(this).text());
        }
    });
}










