<template>
    <div style="height: 100%;max-width: 1920px;margin: 0 auto;">
        <div class="news_list left">
            <div class="enterprise">
                <h2>企業ニュース</h2>
                <ul>
                    <li v-for="(item) in newsList.enterprise" :key="item.id">
                        <a href="javascript:" @click="newsPage(item.id)">
                            <span :class="{'':item.id!==current,'news_item':item.id===current}" @mouseover="mouseOver(item)">{{item.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="brand">
                <h2>ブランドニュース</h2>
                <ul>
                    <li v-for="(item) in newsList.brand"  :key="item.id">
                        <a href="javascript:" @click="newsPage(item.id)">
                            <span :class="{'':item.id!==current,'news_item':item.id===current}" @mouseover="mouseOver(item)">{{item.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="news_content right">
            <a href="javascript:" @click="newsPage(current)">
                <img :class="newsImgClass" :src="imageUrl" alt="" @load="loadImage">
                <div>{{imageTitle}}</div>
            </a>
            <div class="imgIcon" @click="closeNews"><i class="el-icon-close"></i></div>
        </div>
    </div>
</template>

<script>
import { getCookieVal } from "@/assets/js/lang"
export default {
    data() {
        return {
            form:{
                page:1,
                per_page:5,
                language:3,
            },
            newsList:{
                enterprise:[],
                brand:[],
            },
            current:0,
            imageUrl:'',
            imageTitle:'',
            newsImgClass:''
        }
    },
    methods: {
        mouseOver(item){
            this.current = item.id
            this.imageUrl = item.list_img.link
            this.imageTitle = item.title
        },
        newsPage(id){
            this.closeNews()
            this.$router.push({
                path:'/news',
                query:{
                    id
                }
            })
        },
        fechaData(){
            let cookies = getCookieVal("lang")
            switch(cookies){
                case 'zh':
                    this.form.language = 1
                    break;
                case 'trad':
                    this.form.language = 4
                    break;
                case 'en':
                    this.form.language = 2
                    break;
                case 'jap':
                    this.form.language = 3
                    break;
            }
            let params = this.form;
            params.type = 1
            this.$http.post('/super/index/news.php?act=list',params).then(res=>{
                this.newsList.enterprise = res.data.data.list
                this.mouseOver(this.newsList.enterprise[0])
            })
            params.type = 2
            this.$http.post('/super/index/news.php?act=list',params).then(res=>{
                this.newsList.brand = res.data.data.list
            })
        },
        loadImage(){
            let that = this
            that.newsImgClass = 'fadeIn'
            setTimeout(function(){
                that.newsImgClass = ''
            },1000)
        },
        closeNews(){
            let that = this
            that.$parent.newsClass = 'news fadeOutUp'
            let body = document.querySelector('body')
            body.style.overflow = 'unset'
            setTimeout(function(){
                that.$parent.newsVisible = false
            },1000)
        }
    },
    created(){
        this.fechaData()
    },
}
</script>

<style scoped>

</style>
