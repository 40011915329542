<template>
    <div style="margin-top: 56px;">
        <div class="legalPrivacyTabsContainer">

        </div>
    </div>
</template>

<script>
export default {
    name: "legal-privacy",
    data(){
        return{
            screenWidth:null,
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/legal-privacy'){
                    this.$router.replace('/m/legal-privacy')
                }
            }else{
                if(this.$route.path !== '/legal-privacy'){
                    this.$router.replace('/legal-privacy')
                }
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style>

</style>
