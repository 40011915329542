<template>
  <div class="news_info">
    <div class="news_xq">
      <div v-html="form.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:this.$route.query.id,
      form:{}
    }
  },
  watch:{
    '$route.query.id'(news){
      this.id = news
      this.fetchData()
    }
  },
  methods: {
    fetchData(){
      let params = {
        id:this.id
      }
      this.$http.post('/super/admin/news.php?act=detail',params).then(res=>{
        this.form = res.data.data
      })
    }
  },
  created(){
    this.fetchData()
  },
}
</script>

<style scoped>
.news_info{
  max-width: 1250px;
  margin: auto;
  margin-top: 116px;
  min-height: calc(100vh - 291px);
}
.news_xq{
  margin-top: 155px;
  margin-bottom: 60px;
}
.news_xq p{
  text-align: center;
  color: rgb(25, 31, 37);
  font-size: 24px;
}
>>>img{
  max-width: 1250px;
}
>>>video{
  width: 100%;
  height: auto;
}
</style>
