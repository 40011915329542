<template>
    <div class="m-develop">
        <div class="homepage__push homepage-push">
            <img src="@/assets/images/m_/laboratory_banner.jpg" alt="">
        </div>
        <div class="homepage__content">
            <div class="content__title">
                お客様の多様なニーズにお応えするため、
                <br>
                商品企画から海外サポートまでワンステップで支援します。
            </div>
            <div class="content__title_text">
                <span>様々な化粧品アイテムをご提案します。</span><br>
                <span>クレンジング（ローション、ミルク、ジェル、オイル、クリーム）、
                    洗顔（石鹼、フォーム）化粧水、美容液、乳液、クリーム、フェイスマスク 他</span>
            </div>
            <div class="process_title">
                OEMの流れ
            </div>
            <ul class="process">

            </ul>
            <div class="develop_main_p3" style="margin-top: 2rem">
                <p>私たちは研究・開発・生産に専念し、常にナチュラルで健康的な処方を探求し、健康で美しい肌のために若々しさを取り戻すための最先端技術に力を入れています。東京大学応用化学科、医学部（皮膚科学）、大阪工業大学工学部応用化学科などの一流大学から数十名の化粧品薬剤師や開発技術者、アメリカ、フランス、中国から医学・科学アドバイザーを迎えています。</p>
                <p>安全性を第一に考え、世界中のさまざまな自然から「価値ある」原料を探し出すという厳格な姿勢で臨んでいます。</p>
                <p>何十年もの間、私たちは探求と革新を止めることなく、日本の伝統の品質を、最高の科学的厳密さで世界に捧げてきました</p>
            </div>
            <div class="banner" style="margin-top: 2rem">
                <img src="@/assets/images/15649864162.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            screenWidth:null
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/about'){
                    this.$router.replace('/m/about')
                }
            }else{
                if(this.$route.path !== '/about'){
                    this.$router.replace('/about')
                }
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style scoped>
.homepage__push{
    width: 100%;
    font-size: 0;
}

.homepage__push img{
    width: 100%;
}
.homepage__content{
    background: #f5f4f2;
    width: calc(100% - 3.76rem);
    padding: 1.25rem 1.88rem;
}
.content__title{
    width: 100%;
    text-align: center;
    color: #3b3a38;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.2rem;
}
.content__title_text{
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;
    color: #888785;
    font-size: 0.5rem;
    line-height: 1.2rem;
}
.process_title{
    margin-top: 1.5rem;
    width: 100%;
    font-weight: 600;
}
.process{
    margin-top: 0.8rem;
}
.process__item{
    padding: 0.7rem 0.5rem 0.7rem 2rem;
    width: calc(100% - 2.5rem);
    border-left: 1px solid #dbddda;
    border-bottom: 1px solid #dbddda;
    line-height: 1.2rem;
}
.process__item .process__item__text{
    color: #888785;
}
.process__item .process__item__title{
    font-weight: 600;
    color: #3b3a38;
    position: relative;
}
.process__item__title i{
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    background: #dbdddc;
    border-radius: 50%;
    left: -2.35rem;
    top: 0.25rem;
}
.process__item:last-child{
    border-bottom: 0;
}

</style>
