<template>
    <div class="develop">
        <div class="banner">
            <img src="@/assets/images/laboratory_banner.jpg" alt="">
        </div>
        <div class="develop_main">
            <h2 class="develop_main_h2">お客様の多様なニーズにお応えするため、<br>商品企画から海外サポートまでワンステップで支援します。</h2>
            <p class="develop_main_p1">様々な化粧品アイテムをご提案します。</p>
            <p class="develop_main_p2">クレンジング（ローション、ミルク、ジェル、オイル、クリーム）、洗顔（石鹼、フォーム）化粧水、美容液、乳液、クリーム、フェイスマスク 他</p>
            <div class="procedure">
                <h2>OEMの流れ</h2>
                <div class="procedure_main">
                    <ul>
                        <li>
                            <span class="detail_title">ヒアリング</span>
                            <span class="detail_content">お客様のご要望と訴求をお伺いします。</span>
                        </li>
                        <li>
                            <span class="detail_title">企画・提案</span>
                            <span class="detail_content">お客様のコンセプトに合った商品を企画、化粧品開発のノウハウから適切な配合バランスをご提案します。</span>
                        </li>
                        <li>
                            <span class="detail_title">試作</span>
                            <span class="detail_content">企画・提案したものをベースに試作をご依頼いただきます。 ※新規依頼の場合は約14日間</span>
                        </li>
                        <li>
                            <span class="detail_title">処方・資材選定</span>
                            <span class="detail_content">試作品の処方、希望されている容器を決定します。 ※品質保証のため、安定性試験や容器試験などを行います。</span>
                        </li>
                        <li>
                            <span class="detail_title">デザイン入稿/薬事法確認</span>
                            <span class="detail_content">容器・パッケージのデザインや表記を薬事法確認の上、入稿します。※海外進出の場合、関連国の化粧品規制の確認も行います。</span>
                        </li>
                        <li>
                            <span class="detail_title">見積</span>
                            <span class="detail_content">ロット数、容量、納品場所を考慮し、お見積書を提出します。</span>
                        </li>
                        <li>
                            <span class="detail_title">受注</span>
                            <span class="detail_content">ご発注を頂き、商品仕様書を作成します。</span>
                        </li>
                        <li>
                            <span class="detail_title">生産</span>
                            <span class="detail_content">発注書、商品仕様書をもとにご希望の納期に向けて製造を行います。</span>
                        </li>
                        <li>
                            <span class="detail_title">納品</span>
                            <span class="detail_content">出荷検査に合格した商品をお客様が指定された納品場所へ納品期日までに納品いたします。</span>
                        </li>
                        <li>
                            <span class="detail_title">フォローアップ</span>
                            <span class="detail_content">中国（NMPA）をはじめ、関連国の一般貿易許可申請協力や販売支援などを提供できます。</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="develop_main_p3">
                <p>私たちは研究・開発・生産に専念し、常にナチュラルで健康的な処方を探求し、健康で美しい肌のために若々しさを取り戻すための最先端技術に力を入れています。東京大学応用化学科、医学部（皮膚科学）、大阪工業大学工学部応用化学科などの一流大学から数十名の化粧品薬剤師や開発技術者、アメリカ、フランス、中国から医学・科学アドバイザーを迎えています。</p>
                <p>安全性を第一に考え、世界中のさまざまな自然から「価値ある」原料を探し出すという厳格な姿勢で臨んでいます。</p>
                <p>何十年もの間、私たちは探求と革新を止めることなく、日本の伝統の品質を、最高の科学的厳密さで世界に捧げてきました</p>
            </div>
            <div class="banner" style="margin-top: 3rem">
                <img src="@/assets/images/15649864162.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {getCookieVal} from "@/assets/js/lang";

export default {
    data(){
        return{
            screenWidth:null,
            lang:'zh'
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/about'){
                    this.$router.replace('/m/about')
                }
            }else{
                if(this.$route.path !== '/about'){
                    this.$router.replace('/about')
                }
            }
        }
    },
    mounted() {
        this.lang = getCookieVal('lang');
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style scoped>
.develop{
    background: #f5f4f2;
    padding: 180px 10rem 4vh;
    text-align: center;
}
.banner{
    width: 100%;
}
.banner img{
    width: 100%;
    height: auto;
}


.procedure{
    margin: 0 auto 4rem;
    max-width: 1000px;
    margin-top: 90px;
}
.procedure{
    text-align: left;
}
.procedure_main{
    margin-top: 20px;
}
.procedure_main ul{
    font-size: 15px;
    color: #82817f;
    line-height: 21px;
}
ul li{
    width: 100%;
}
.detail_title{
    display: inline-block;
    width: 200px;
    padding: 10px 0;
}
.detail_content{
    padding: 10px 0;
    padding-left: 35px;
    display: inline-block;
    width: calc(100% - 240px);
    border-left: 1px solid #756d6b;
    height: 100%;
}
</style>
