<template>
  <div class="modal__contents">
    <h1 class="modal__title">
      <span>お問い合せ</span>
    </h1>
    <button class="modal__close icon-button -light" @click="closeLogin">
      <i class="el-icon-close"></i>
    </button>
    <div class="modal__content contact_modal__content">
      <span>住所</span>
      <div>日本東京都新宿区西早稲田一丁目9番35-302号</div>
      <span>TEL</span>
      <div>03-6820-0668</div>
      <span>FAX</span>
      <div>03-6820-0668</div>
      <span>E-MAIL</span>
      <div>info@wisdomjp.co.jp</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    closeLogin(){
      this.$parent.closeLogin();
    }
  }
}
</script>

<style scoped>

</style>
