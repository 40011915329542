<style>
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Sans+SC:wght@300;400;500;700&family=Noto+Sans+TC:wght@300;400;500;700&display=swap')
</style>
<template>
    <div id="app">
        <header-page></header-page>
        <router-view class="router-view" />
        <footer-page></footer-page>
        <div class="first_start" id="first_start" v-if="firstStart">
            <video id="inse_voide" style="height: 100vh; object-fit: fill;position: relative;" webkit-playsinline="true" playsinline="true" autoplay muted src="@/assets/images/start.mp4"></video>
        </div>
    </div>
</template>
<script>
import HeaderPage from '@/components/public/header'
import FooterPage from '@/components/public/footer'
import {indexConfig, notIndexConfig} from "@/assets/js/commom";
import { getCookieVal } from "@/assets/js/lang"
export default {
    name: 'App',
    components: {
        HeaderPage,
        FooterPage
    },
    data(){
        return{
            firstStart:false,
            screenWidth:null,
            dict:{}
        }
    },
    watch: {
        $route(to){
            this.editTitle(to.path)
        },
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        editTitle(path = this.$route.path){
            switch (path){
                case '/index':
                    document.title = this.dict.index.metaTitle
                    break;
                case '/about':
                    document.title = this.dict.about.metaTitle
                    break;
                case '/brief':
                    document.title = this.dict.brief.metaTitle
                    break;
                case '/contact':
                    document.title = this.dict.contact.metaTitle
                    break;
                case '/legal-privacy':
                    document.title = this.dict.legalPrivacy.metaTitle
                    break;
                case '/news':
                    document.title = this.dict.news.metaTitle
                    break;
            }
        },
        fechaData(){
            let lang = getCookieVal("lang");
            let locationHost = window.location.host
            if(lang == null){
                switch (locationHost) {
                    case 'wisdomjp.co.jp':
                        lang = 'jap'
                        break;
                    case 'wisdomjp.com':
                        lang = 'en'
                        break;
                    default:
                        lang = 'zh'
                }
            }
            this.dict = require('@/assets/js/'+ lang +'.json')
            this.editTitle()
        },
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/index'){
                    this.$router.replace('/m/index')
                }
            }else{
                if(this.$route.path !== '/index'){
                    this.$router.replace('/index')
                }
            }
        }
    },
    mounted() {
        this.fechaData()
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    created() {
        // 第一次访问，出现动画
        let firstStart = localStorage.getItem('firstStart');
        if(!firstStart){
            let that = this;
            document.querySelector('body').style.overflow = 'hidden'
            that.firstStart = true;
            localStorage.setItem('firstStart',true)
            setTimeout(function(){
                document.getElementById('first_start').classList.add('slideOutRight');
                document.querySelector('body').style.overflow = 'unset'
                setTimeout(function(){
                    that.firstStart = false;
                },2000)
            },7600)
        }
    }
}
</script>

<style>
body{
    font-family: 'Noto Sans JP', sans-serif;
    margin: 0;
    letter-spacing: 0;
    line-height: normal;
    font-style: normal;
    font-size: 1rem;
    font-weight: 300;
    color: #606060;
    max-width: 1920px;
    margin: 0 auto;
}
div,p,button,h1,h2,h3,h4,h5,h6,span,a,ul,li{
    font-family: 'Noto Sans JP', sans-serif;
}
a{
    text-decoration:none;
    color:#606060;
}
ul{
    list-style: none;
    margin: 0;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
.first_start{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    text-align: center;
}
.first_start img{
    width: 100%;
    height: 100%;
}
</style>
