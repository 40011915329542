<template>
    <div v-if="!Webpage">
        <header id="header" role="banner" class="header">
            <div class="header__main">
                <div class="header__container" id="header__container">
                    <div class="logo header__logo" style="z-index: 9999">
                        <a href="javascript:" class="smart-link logo__wrap" @click="openPage(1)">
                            <img src="@/assets/images/header_logo.png" alt="" style="height: auto;width: 12.1875rem;">
                        </a>
                    </div>
                    <nav aria-label="ユーティリティ" role="navigation" class="header__utility">
                        <ul class="header__utility-items list">
                            <li class="header__utility-item -search" style="margin-top: 35px;">
                                <a href="javascript:">
                                    <img src="@/assets/images/logo.png" alt="" style="width: 4rem;height: auto;">
                                </a>
                            </li>
<!--                            <li class="header__utility-item -my" @click="showContact">-->
<!--                                <a href="javascript:">-->
<!--                                    お問い合せ-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li class="header__utility-item -cart" @click="showLogin">-->
<!--                                <a href="javascript:">-->
<!--                                    ログイン-->
<!--                                </a>-->
<!--                            </li>-->
                            <li class="header__utility-item -lang">
                                <a href="javascript:">
                                    <span>日本语</span><i class="el-icon-caret-bottom"></i>
                                </a>
                                <div class="hdr_sedsp">
                                    <div class="hdr_sedsp_rgt">
                                        <article class="stepcll">
                                            <a @click="setLang(1)" href="javascript:void(0)" class="steic_tel ate_sperro" id="zhBtn">简体中文</a>
                                        </article>
                                        <article class="stepcll">
                                            <a @click="setLang(2)" href="javascript:void(0)" class="steic_tel ate_sperro" id="tradBtn">繁体中文</a>
                                        </article>
                                        <article class="stepcll">
                                            <a @click="setLang(3)" href="javascript:void(0)" class="steic_tel ate_sperro" id="enBtn">English</a>
                                        </article>
                                        <article class="stepcll">
                                            <a @click="setLang(4)" href="javascript:void(0)" class="steic_tel ate_sperro" id="japBtn" >日本语</a>
                                        </article>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <nav class="header__main-nav" aria-label="メイン" role="navigation">
                        <ul class="header-main-nav lv-list">
                            <li :class="liClass === 1 ? 'header-main-nav__item brand_li' : 'brand_li'">
                                <button>
                                    <span class="header-main-nav__item_span">品牌一览</span>
                                </button>
                                <div class="brand" style="display: none">
                                    <a href="https://memoryyoung.com/">
                                        <img src="@/assets/images/brand_my.jpg" alt="">
                                        <p>MY</p>
                                    </a>
                                    <a href="https://guexy.com/">
                                        <img src="@/assets/images/brand_guexy.jpg" alt="">
                                        <p>GUEXY</p>
                                    </a>
                                </div>
                            </li>
                            <li :class="liClass === 2 ? 'header-main-nav__item' : ''">
                                <button @click="openPage(2)">
                                    <span class="header-main-nav__item_span">产品一览</span>
                                </button>
                            </li>
                            <li :class="liClass === 3 ? 'header-main-nav__item' : ''">
                                <button @click="openPage(3)">
                                    <span class="header-main-nav__item_span">NEWS</span>
                                </button>
                            </li>
                            <li :class="liClass === 4 ? 'header-main-nav__item' : ''">
                                <button @click="openPage(4)">
                                    <span class="header-main-nav__item_span">OEM事業</span>
                                </button>
                            </li>
                            <li :class="liClass === 5 ? 'header-main-nav__item' : ''">
                                <button @click="openPage(5)">
                                    <span class="header-main-nav__item_span">会社概要</span>
                                </button>
                            </li>
                            <li :class="liClass === 6 ? 'header-main-nav__item' : ''">
<!--                                <button @click="showContact">-->
                                <button @click="openPage(6)">
                                    <span class="header-main-nav__item_span">联系我们</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
        <!--    登录和联系方式    -->
        <div class="vue-portal-target" v-if="showVisible">
            <div :class="cssBackdrop" @click="closeLogin"></div>
            <div :class="cssSidePanel">
                <login v-if="showLoginVisible"></login>
                <contact-page v-if="contactVisible"></contact-page>
<!--                <div class="modal__contents"  v-if="showLangVisible">-->
<!--                    <h1 class="modal__title">-->
<!--                        <span>语言</span>-->
<!--                    </h1>-->
<!--                    <div class="modal__content contact_modal__content">-->
<!--                        <button @click="setLang(1)">简体中文</button>-->
<!--                        <button @click="setLang(2)">繁體中文</button>-->
<!--                        <button @click="setLang(3)">ENGLISH</button>-->
<!--                        <button @click="setLang(4)">日本語</button>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        <!--    新闻    -->
        <div :class="newsClass" v-if="newsVisible">
            <news-list ref="news"></news-list>
        </div>
    </div>
    <m-header v-else></m-header>
</template>

<script>
// import "@/assets/js/jquery.min"
import { setLanguage,translate } from "@/assets/js/lang"
import { indexConfig,notIndexConfig } from "@/assets/js/commom";
import NewsList from '@/components/public/newsList'
import Login from '@/components/public/login'
import MHeader from '@/components/public/m_header'
import ContactPage from '@/components/public/contactPage'
export default {
    components: {
        NewsList,
        MHeader,
        Login,
        ContactPage
    },
    data() {
        return {
            showLoginVisible:false,
            newsVisible:false,
            productVisible:false,
            showVisible:false,
            contactVisible:false,
            // showLangVisible:false,
            cssBackdrop:'backdrop modal__backdrop',
            cssSidePanel:'modal -sidepanel',
            newsClass:'news',
            productClass:'news',
            liClass:0,
            Webpage:false,
            selang:4,
            screenWidth:null
        }
    },
    watch:{
        $route(to){
            console.log(to)
            if(this.screenWidth > 600){
                if(to.path === '/index'){
                    indexConfig()
                }else if(to.path === '/enterprise'){
                    indexConfig()
                }else{
                    notIndexConfig()
                }
            }
        },
        screenWidth (val){
            if(val < 600) {
                this.Webpage = true
            }else{
                this.Webpage = false
            }
        }
    },
    methods: {
        setLang(type){
            var that=this;
            if(type == '1'){
                setLanguage("zh");
                that.selang=1;
            }else if(type == '2'){
                setLanguage("trad");
                that.selang=2;
            }else if(type == '3'){
                setLanguage("en");
                that.selang=3;
            }else if(type == '4'){
                setLanguage("jap");
                that.selang=4;
            }
            setTimeout(function (){
                that.$parent.editTitle()
                that.$parent.fechaData()
            }, 200);
            that.showVisible = false
        },
        openPage(type){
            switch (type){
                case 1:
                    this.$router.push({
                        path:'/index'
                    })
                    break;
                case 2:
                    window.location.href = 'https://shop.wisdomjp.co.jp/'
                    break;
                case 3:
                    document.querySelector('body').style.overflow = 'hidden'
                    this.productVisible = false
                    this.newsClass = 'news fadeInDown'
                    this.newsVisible = true
                    break;
                case 4:
                    this.$router.push({
                        path:'/about'
                    })
                    break;
                case 5:
                    this.$router.push({
                        path:'/brief'
                    })
                    break;
                case 6:
                    window.location.href = '/contact'
                    break;
            }
            this.liClass = type;
        },
        visibleShow(){
            this.cssBackdrop = 'backdrop modal__backdrop fadeIn'
            this.cssSidePanel = 'modal -sidepanel fadeInRight'
            this.showVisible = true;
        },
        showContact(){
            this.visibleShow();
            this.contactVisible = true;
        },
        showLogin(){
            this.visibleShow();
            this.showLoginVisible = true;
        },
        showLang(){
            this.visibleShow();
            // this.showLangVisible = true;
        },
        closeLogin(){
            let that = this;
            this.cssSidePanel = 'modal -sidepanel fadeOutRight';
            this.cssBackdrop = 'backdrop modal__backdrop fadeOut';
            setTimeout(function(){
                that.showLoginVisible = false;
                // that.showLangVisible = false;
                that.showVisible = false;
                that.contactVisible = false;
                this.cssSidePanel = 'modal -sidepanel'
                this.cssBackdrop = 'backdrop modal__backdrop'
            },1000);
        }
    },
    mounted() {
        translate();
        this.screenWidth = document.body.clientWidth
        if(this.screenWidth > 600){
            indexConfig()
        }
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    }
}
</script>

<style scoped>

</style>
