<template>
    <div class="m-enterprise">
        <div class="homepage__push homepage-push" style="font-size: 0">
            <img src="@/assets/images/m_/enterprise_banner.jpg" alt="">
            <img class="cen_img" :src="require('@/assets/images/m_/'+lang+'_enterprise.jpg')" alt="">
            <p style="text-align: center">
                <video autoplay muted loop controls class="enterprise_video">
                    <source class="enterprise_mp4" :src="require('@/assets/images/' + lang + '_enterprise.mp4')" type="video/mp4">
                </video>
            </p>
        </div>
    </div>
</template>

<script>
import {getCookieVal} from "@/assets/js/lang";
export default {
    data(){
        return{
            screenWidth:null,
            lang:'jap'
        }
    },
    watch: {
        screenWidth (val){
            if(this.$route.path === '/enterprise'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/enterprise'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/enterprise'){
                    this.$router.replace('/m/enterprise')
                }
            }else{
                if(this.$route.path !== '/enterprise'){
                    this.$router.replace('/enterprise')
                }
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    created(){
        this.lang = getCookieVal('lang')
    }
}
</script>

<style scoped>

</style>
