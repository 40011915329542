<template>
    <div class="footer gutters default-layout__footer">
        <div class="footer-breadcrumb">
            <a href="/">
                <img src="@/assets/images/logo_2.png" alt="">
            </a>
        </div>
        <div class="footer-navigation">
            <ul class="lv-list">
                <li class="lv-list_li"><a href="/">ホーム</a></li>
                <li class="lv-list_li"><a href="/brief">会社紹介</a></li>
                <li class="lv-list_li"><a href="https://memoryyoung.com/">MY</a></li>
                <li class="lv-list_li"><a href="https://guexy.com/">企業ニュース</a></li>
                <li class="lv-list_li"><a href="/legal-privacy">ブランドニュース</a></li>
                <li class="lv-list_li"><a href="/about">お問い合せ</a></li>
            </ul>
            <!--      <div class="copyright" v-if="!Webpage">粤ICP备17036825号|◎2022蜜蜂在线贸易(深圳)有限公司版权所有</div>-->
            <!--      <div class="copyright" v-else>粤ICP备17036825号<br>◎2022蜜蜂在线贸易(深圳)有限公司版权所有</div>-->
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            Webpage:false,
            screenWidth:null
        }
    },
    watch:{
        screenWidth (val){
            if(val < 600) {
                this.Webpage = true
            }else{
                this.Webpage = false
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    }
}
</script>

<style scoped>
.copyright{
    padding:10px 0 40px;
}
</style>
