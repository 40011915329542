<template>
    <main class="default-layout__content">
        <div class="index_banner" style="margin-bottom: 5rem">
            <a href="#" @click="openPage('enterprise')">
                <img src="@/assets/images/enterprise_banner.jpg" alt="">
            </a>
        </div>
        <div class="index_banner">
            <a href="/about">
                <img src="@/assets/images/laboratory_banner.jpg" alt="">
            </a>
        </div>
<!--        <div class="index_banner first">-->
<!--            <img src="@/assets/images/zh_pl_banner.jpg" alt="">-->
<!--            <a href="/product?cat=1" class="banner_jump_link">-->
<!--                コレクション一覧へ-->
<!--            </a>-->
<!--        </div>-->
<!--        <div class="index_banner second">-->
<!--            <img src="@/assets/images/zh_hl_banner.jpg" alt="">-->
<!--            <a href="/product?cat=0" class="banner_jump_link" >-->
<!--                コレクション一覧へ-->
<!--            </a>-->
<!--        </div>-->
        <section class="homepage__services">
            <div class="homepage__services-wrapper">
                <ul class="content-push-list list" style="margin-bottom: 2.5rem">
                    <li class="content-push-list__item first">
                        <a href="/product?cat=1" target="_blank" class="smart-link content-push list-label-xl -landscape false">
                            <div class="content-push__wrapper">
                                <div class="content-push__media">
                                    <img src="@/assets/images/zh_pl_banner.jpg" alt="">
                                </div>
                                <div class="content-push__content">
                                    <div class="content-push__link">
                                        <span>詳細はこちら</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="content-push-list__item second">
                        <a href="/product?cat=0" target="_blank" class="smart-link content-push list-label-xl -landscape false">
                            <div class="content-push__wrapper">
                                <div class="content-push__media">
                                    <img src="@/assets/images/zh_hl_banner.jpg" alt="">
                                </div>
                                <div class="content-push__content">
                                    <div class="content-push__link">
                                        <span>詳細はこちら</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <ul class="content-push-list list">
                    <li class="content-push-list__item">
                        <a href="https://memoryyoung.com/" target="_blank" class="smart-link content-push list-label-xl -landscape false">
                            <div class="content-push__wrapper">
                                <div class="content-push__media">
                                    <img src="@/assets/images/my_url.jpg" alt="">
                                </div>
                                <div class="content-push__content">
                                    <div class="content-push__link">
                                        <span>詳細はこちら</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="content-push-list__item">
                        <a href="https://guexy.com/" target="_blank" class="smart-link content-push list-label-xl -landscape false">
                            <div class="content-push__wrapper">
                                <div class="content-push__media">
                                    <img src="@/assets/images/guexy.jpg" alt="">
                                </div>
                                <div class="content-push__content">
                                    <div class="content-push__link">
                                        <span>詳細はこちら</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </main>
</template>

<script>
import {getCookieVal} from "@/assets/js/lang";
export default {
    name: 'indexPage',
    data() {
        return {
            lang:'jap',
            banners:{
                zh:[require('@/assets/images/zh_pl_banner.jpg'),require('@/assets/images/zh_hl_banner.jpg')],
                trad:[require('@/assets/images/trad_pl_banner.jpg'),require('@/assets/images/trad_hl_banner.jpg')],
                jap:[require('@/assets/images/jap_pl_banner.jpg'),require('@/assets/images/jap_hl_banner.jpg')],
                en:[require('@/assets/images/en_pl_banner.jpg'),require('@/assets/images/en_hl_banner.jpg')],
            }
        }
    },
    props: {
        msg: String
    },
    methods: {
        openPage(type){
            switch (type) {
                case 'enterprise':
                    this.$router.push({
                        path: '/enterprise'
                    })
                    break;
            }
        }
    },
    created(){
        this.lang = getCookieVal('lang') ?? this.lang
    }
}
</script>

<style scoped>

</style>
