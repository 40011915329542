<template>
    <div class="m_header">
        <header id="header" class="header default-layout__header sticky">
            <div class="header__main -transparent -contrast">
                <div class="header__container">
                    <div class="logo header__logo">
                        <span>
                            <a href="/m/index"><img :src="logo" alt=""></a>
                        </span>
                    </div>
                    <nav class="header__main-nav-burger">
                        <div class="lv-header__lock">
                            <div>
                                <button @click="closeMenuLogin" v-show="mobileLogin">
                                    <img :src="whiteCloseMenu" alt="">
                                </button>
                                <button @click="menuShow" v-show="!mobileMenu && !mobileLogin">
                                    <img class="menu" :src="whiteMenu" alt="">
                                </button>
                                <button @click="closeMenuShow" v-show="mobileMenu && !mobileLogin">
                                    <img :src="whiteCloseMenu" alt="">
                                </button>
                                <div class="header-mobile-menu" v-show="mobileMenu">
                                    <ul class="header-main-nav list" v-show="!newsShow">
                                        <li class="header-main-brand header-main-nav-panel__trigger" @click="openPage(1)">
                                            <button>
                                                <span>品牌一览</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                            <div class="brand__list" v-show="brandShow">
                                                <div class="brand__item">
                                                    <a href="https://memoryyoung.com/">MY</a>
                                                </div>
                                                <div class="brand__item">
                                                    <a href="https://guexy.com/">GUEXY</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="header-main-nav-panel__trigger" @click="openPage(2)">
                                            <button>
                                                <span>官方商城</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                        </li>
                                        <li class="header-main-nav-panel__trigger" @click="openPage(3)">
                                            <button>
                                                <span>最新资讯</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                        </li>
                                        <li class="header-main-nav-panel__trigger" @click="openPage(4)">
                                            <button>
                                                <span>OEM事业</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                        </li>
                                        <li class="header-main-nav-panel__trigger" @click="openPage(5)">
                                            <button>
                                                <span>企业简介</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                        </li>
                                        <li class="header-main-nav-panel__trigger" @click="openPage(6)" style="display: none">
                                            <button>
                                                <span>联系我们</span>
                                                <i class="el-icon-arrow-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="header-main-news" v-if="newsShow">
                                        <div class="news__title">
                                            <div class="news__title_clone" @click="newsShow = false">
                                                <i class="el-icon-arrow-left"></i>
                                            </div>
                                            <div class="news__title_text">NEWS</div>
                                        </div>
                                        <div class="news__list">
                                            <div class="news__list__title">
                                                <button>
                                                    <span>企業ニュース</span>
                                                </button>
                                            </div>
                                            <ul class="main_news_item">
                                                <li v-for="(item) in newsList.enterprise" :key="item.id">
                                                    <a href="javascript:" @click="newsPage(item.id)">
                                                        {{item.title}}
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="news__list__title">
                                                <button>
                                                    <span>ブランドニュース</span>
                                                </button>
                                            </div>
                                            <ul class="main_news_item">
                                                <li v-for="(item) in newsList.brand"  :key="item.id">
                                                    <a href="javascript:" @click="newsPage(item.id)">
                                                        {{item.title}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul class="header-mobile-menu__services list" v-show="!newsShow">
                                        <li class="header-mobile-menu__services__item">
                                            <div class="title first__title">お問い合せ</div>
                                        </li>
                                        <li class="header-mobile-menu__services__item">
                                            <div class="title">住所</div>
                                            <div class="black_box">日本東京都新宿区西早稲田一丁目9番35-302号</div>
                                        </li>
                                        <li class="header-mobile-menu__services__item">
                                            <div class="title">TEL</div>
                                            <div class="black_box">03-6820-0668</div>
                                        </li>
                                        <li class="header-mobile-menu__services__item">
                                            <div class="title">FAX</div>
                                            <div class="black_box">03-6820-0668</div>
                                        </li>
                                        <li class="header-mobile-menu__services__item">
                                            <div class="title">E-MAIL</div>
                                            <div class="black_box">info@wisdomjp.co.jp</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <nav class="header__main-login">
                        <div class="lv-header__lock">
                            <button class="lv-header__lock_lang" @click="loginShowBut">LANG</button>
                            <div class="header-mobile-menu" v-if="mobileLogin">
                                <ul class="lang_list">
                                    <li @click="setLang(1)"><button>简体中文</button></li>
                                    <li @click="setLang(2)"><button>繁體中文</button></li>
                                    <li @click="setLang(3)"><button>ENGLISH</button></li>
                                    <li @click="setLang(4)"><button>日本語</button></li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                </div>
            </div>
        </header>
    </div>
</template>

<script>
import { setLanguage,getCookieVal } from "@/assets/js/m_lang"
// import Login from '@/components/public/login'
export default {
    components: {
        // Login
    },
    data(){
        return{
            startY:0,
            scrollTop:'',
            headerLogo:require('@/assets/images/header_logo.png'),
            blackMenu:require('@/assets/images/m_/black_menu.png'),
            blackLogin:require('@/assets/images/m_/black_login.png'),
            logo:require('@/assets/images/logo_2.png'),
            whiteMenu:require('@/assets/images/m_/white_menu.png'),
            whiteCloseMenu:require('@/assets/images/m_/close_menu.png'),
            whiteLogin:require('@/assets/images/m_/white_login.png'),
            selang:3,
            mobileMenu:false,
            iconStatus:false,
            brandShow:false,
            newsShow:false,
            newsForm:{
                page:1,
                per_page:5,
                language:3,
            },
            newsList:{
                enterprise:[],
                brand:[],
            },
            mobileLogin:false
        }
    },
    methods:{
        newsPage(id){
            let href = window.location.protocol + '//' +window.location.host;
            window.location.href = href + '/m/news?id=' + id
        },
        // 切换语音按钮
        setLang(type){
            var that=this;
            if(type == '1'){
                setLanguage("zh");
                that.selang=1;
            }else if(type == '2'){
                setLanguage("trad");
                that.selang=4;
            }else if(type == '3'){
                setLanguage("en");
                that.selang=2;
            }else{
                setLanguage("jap");
                that.selang=3;
            }
            this.mobileLogin = false
            this.closeMenuLogin()
            this.getNewsList()
        },
        //登录显示按钮
        loginShowBut(){
            document.removeEventListener('scroll', this.handleScroll);
            let transparent = document.querySelector('.-transparent');
            transparent.style.background = '#ffffff'
            // 菜单按钮
            document.querySelector('.header__logo img').setAttribute('src', this.headerLogo)
            this.mobileLogin = true
            let body = document.querySelector('body');
            body.style.position = 'fixed';
            body.style.overflow = 'hidden';
            body.style.width = '100%';
            body.style.top = '0px';
        },
        // 关闭菜单按钮
        closeMenuLogin(){
            let transparent = document.querySelector('.-transparent');
            if(this.$route.path === '/m/legal-privacy' || this.$route.path === '/m/news'){
                transparent.style.background = '#19110b'
            }else{
                transparent.style.background = 'transparent'
            }
            // 菜单按钮
            document.querySelector('.header__logo img').setAttribute('src', this.logo)
            document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.whiteMenu)
            document.querySelector('.lv-header__lock_lang').style.color = '#fff'
            this.mobileLogin = false
            let body = document.querySelector('body');
            body.style.position = '';
            body.style.overflow = '';
            body.style.width = '';
            body.style.top = '';
            document.addEventListener('scroll', this.handleScroll, true);
        },
        //函数防抖
        debounce(func, wait) {
            let timeout;
            return function () {
                let context = this;
                let args = arguments;
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    func.apply(context, args)
                }, wait);
            }
        },
        //判断滚动方向
        handleMouse(e){
            if(this.mobileMenu) return;
            if(this.mobileLogin) return;
            let transparent = document.querySelector('.-transparent');
            if(e.deltaY > 0){
                transparent.style.transform = 'translateY(-56px)'
                document.removeEventListener('scroll', this.handleScroll);
            }else{
                transparent.style.transform = 'translateY(0px)'
                document.addEventListener('scroll', this.handleScroll, true);
            }
        },
        handleTouchstart(e){
            this.startY = e.changedTouches[0].pageY;
        },
        handleTouchmove(e){
            let transparent = document.querySelector('.-transparent');
            let top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
            let moveEndY = e.changedTouches[0].pageY
            let Y = moveEndY - this.startY;
            if ( Y < 0) {
                if(top > 0){
                    transparent.style.transform = 'translateY(-56px)'
                }
                document.removeEventListener('scroll', this.handleScroll);
            }else{
                transparent.style.transform = 'translateY(0px)'
                document.addEventListener('scroll', this.handleScroll, true);
            }
        },
        handleScroll(){
            if(this.mobileMenu && !this.mobileLogin) return;
            if(!this.mobileMenu && this.mobileLogin) return;
            this.scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
            let transparent = document.querySelector('.-transparent');
            if(this.scrollTop > 160){
                transparent.style.background = '#ffffff'
                // 菜单按钮
                document.querySelector('.header__logo img').setAttribute('src', this.headerLogo)
                document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.blackMenu)
                document.querySelector('.lv-header__lock_lang').style.color = '#000'
                // document.querySelector('.header__main-login img').setAttribute('src', this.blackLogin)
            }else{
                if(this.$route.path === '/m/legal-privacy' || this.$route.path === '/m/news'){
                    transparent.style.background = '#19110b'
                    document.querySelector('.header__logo img').setAttribute('src', this.logo)
                    document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.whiteMenu)
                    document.querySelector('.lv-header__lock_lang').style.color = '#fff'
                }else{
                    transparent.style.background = 'transparent'
                    // 菜单按钮
                    document.querySelector('.header__logo img').setAttribute('src', this.logo)
                    document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.whiteMenu)
                    document.querySelector('.lv-header__lock_lang').style.color = '#fff'
                    // document.querySelector('.header__main-login img').setAttribute('src', this.whiteLogin)
                }
            }
        },
        // 点击菜单按钮
        menuShow(){
            document.removeEventListener('scroll', this.handleScroll);
            let transparent = document.querySelector('.-transparent');
            transparent.style.background = '#ffffff'
            // 菜单按钮
            document.querySelector('.header__logo img').setAttribute('src', this.headerLogo)
            document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.blackMenu)
            document.querySelector('.lv-header__lock_lang').style.color = '#fff'
            // document.querySelector('.header__main-login img').setAttribute('src', this.blackLogin)
            this.mobileMenu = true
            let body = document.querySelector('body');
            body.style.position = 'fixed';
            body.style.overflow = 'hidden';
            body.style.width = '100%';
            body.style.top = '0px';
        },
        closeMenuShow(){
            let transparent = document.querySelector('.-transparent');
            transparent.style.background = 'transparent'
            // 菜单按钮
            document.querySelector('.header__logo img').setAttribute('src', this.logo)
            document.querySelector('.header__main-nav-burger .menu').setAttribute('src', this.whiteMenu)
            // document.querySelector('.header__main-login img').setAttribute('src', this.whiteLogin)
            this.mobileMenu = false
            let body = document.querySelector('body');
            body.style.position = '';
            body.style.overflow = '';
            body.style.width = '';
            body.style.top = '';
            document.addEventListener('scroll', this.handleScroll, true);
        },
        openPage(type){
            let href = window.location.protocol + '//' +window.location.host;
            switch (type){
                case 1:
                    if(!this.iconStatus){
                        document.querySelector('.header-main-brand i').setAttribute('class', 'el-icon-arrow-down')
                        this.iconStatus = true
                        this.brandShow = true
                    }else{
                        document.querySelector('.header-main-brand i').setAttribute('class', 'el-icon-arrow-right')
                        this.iconStatus = false
                        this.brandShow = false
                    }
                    break;
                case 2:
                    window.location.href = 'https://shop.wisdomjp.co.jp/'
                    break;
                case 3:
                    this.newsShow = true
                    break;
                case 4:
                    window.location.href = href + '/m/about'
                    break;
                case 5:
                    window.location.href = href + '/m/brief'
                    break;
            }

        },
        getNewsList(){
            let cookies = getCookieVal("lang")
            switch(cookies){
                case 'zh':
                    this.newsForm.language = 1
                    break;
                case 'trad':
                    this.newsForm.language = 4
                    break;
                case 'en':
                    this.newsForm.language = 2
                    break;
                case 'jap':
                    this.newsForm.language = 3
                    break;
            }
            this.newsForm.language = this.selang
            let params = this.newsForm;
            params.type = 1
            this.$http.post('/super/index/news.php?act=list',params).then(res=>{
                this.newsList.enterprise = res.data.data.list
            })
            params.type = 2
            this.$http.post('/super/index/news.php?act=list',params).then(res=>{
                this.newsList.brand = res.data.data.list
            })
        }
    },
    mounted () {
        if(this.$route.path === '/m/legal-privacy' || this.$route.path === '/m/news'){
            let transparent = document.querySelector('.-transparent');
            transparent.style.background = '#19110b'
        }
        let cookies = getCookieVal("lang")
        let type = 4;
        if(cookies != null){
            switch(cookies){
                case 'zh':
                    type = 1
                    break;
                case 'trad':
                    type = 2
                    break;
                case 'en':
                    type = 3
                    break;
                case 'jap':
                    type = 4
                    break;
            }
        }
        this.setLang(type)
        //监听鼠标滚动事件
        window.addEventListener('mousewheel', this.debounce(this.handleMouse,1), true)||window.addEventListener("DOMMouseScroll",this.debounce(this.handleMouse,1),false)
        window.addEventListener('touchmove', this.handleTouchmove,true)
        window.addEventListener('touchstart', this.handleTouchstart,true)
    },
}
</script>

<style>
    style attribute {
        --vh: 6.67px;
    }
    body{
        font-size: 0.9rem !important;
    }
    .header{
        height: 116px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .header__main{
        top: 0;
        transform: translateY(0px);
        position: relative;
    }
    .lv-header__lock_lang{
        padding: 0;
        color: #fff;
    }
    .header-main-nav-panel__trigger button{
        color: #000000;
    }
    .sticky>* {
        left: 0;
        right: 0;
        position: fixed!important;
        z-index: 3;
    }
    .m_header #header .header__container{
        height: 3.5rem;
        color: #fff;
        background: transparent;
        transition: all .3s cubic-bezier(.39,.575,.565,1);
        box-shadow: none;
    }
    #header .header__logo {
        width: 12.625rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 3.5rem;
        text-align: center;
    }
    .header__logo span{
        display: inline-block;
        width: 100%;
        height: 3.5rem;
        line-height: 5rem;
    }
    .header__logo span img{
        width: 100%;
        height: auto;
    }
    .header__main-nav-burger{
        height: 3.5rem;
        line-height: 4.5rem;
        position: absolute;
        top: 0;
        left: 0.5rem;
    }
    .header__main-nav-burger button{
        background: transparent;
        transition: all .3s cubic-bezier(.39,.575,.565,1);
        box-shadow: none;
        border: 0;
    }
    .header__main-nav-burger img{
        width: 1.5rem;
        height: 1.3rem;
    }
    .header__main-login{
        height: 3.5rem;
        line-height: 3.5rem;
        position: absolute;
        top: 0;
        right: 0.5rem;
    }
    .header__main-login button{
        background: transparent;
        transition: all .3s cubic-bezier(.39,.575,.565,1);
        box-shadow: none;
        border: 0;
    }
    .header__main-login img{
        width: 1.3rem;
        height: auto;
    }

    .header-mobile-menu{
        position: fixed;
        left: 0;
        right: 0;
        background: #FFFFFF;
        top: 3.5rem;
        height: calc(var(--vh, 1vh)*100 - 3.5rem);
        z-index: 10;
        border-top: 1px solid #ebebeb;
        overflow: auto;
    }
    .header-main-nav{
        padding: 0 2rem;
    }
    .header-main-nav-panel__trigger{
        padding: 10px 0;
        border-bottom: 1px solid #ebebeb;
    }
    .brand__list{
        margin-top: 12px;
        padding: 0 25px;
    }
    .brand__item{
        text-align: center;
        height: 2.5rem;
        line-height: 2.5rem;
        border-top: 1px solid #e5e5e5;
    }
    .lang_list{
        padding: 0 20px;
    }
    .lang_list li{
        background: #000000;
        margin-top: 20px;
        text-align: center;
    }
    .lang_list li button{
        color: #FFFFFF;
    }
    .header-main-nav-panel__trigger button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }
    .header-main-nav-panel__trigger .el-icon-arrow-right{
        font-size: 1rem;
    }
    .header-mobile-menu__services{
        width: calc(100% - 2.5rem);
        margin: 2.5rem 1.25rem;
        background: #f5f4f2;
        padding-bottom: 1.2rem;
    }
    .header-mobile-menu__services__item{
        width: calc(100% - 2rem);
        margin: 0 1rem;
        margin-bottom: 15px;
        color: #000;
        line-height: initial;
    }
    .header-mobile-menu__services__item .black_box{
        height: 2.5rem;
        line-height: 2.5rem;
        background: #000000;
        color: #FFFFFF;
        font-size: 0.7rem;
        padding-left: 4px;
    }
    .header-mobile-menu__services__item .title{
        margin-left: 2px;
        margin-bottom: 5px;
    }
    .first__title{
        height: 2.8rem;
        line-height: 4.2rem;
    }
    .header-main-news{
        color: #000000;
    }
    .news__title{
        line-height: 0;
        border-bottom: 1px solid #F0F0F0;
    }
    .news__title div{
        display: inline-block;
        height: 40px;
        line-height: 40px;
    }
    .news__title .news__title_text{
        width: calc(100% - 46px);
        text-align: center;
    }
    .news__title .news__title_clone{
        text-align: center;
        border-right: 1px solid #F0F0F0;
        width: 45px;
    }
    .news__list{
        padding: 30px 40px;
        line-height: 40px;
    }
    .news__list__title{
        color: #3e3534;
        border-bottom: 1px solid #f1f1f1;
        font-size: 14px;
    }
    .main_news_item{
        display: block;
    }
    .main_news_item li{
        color: #bebbba;
        font-size: 13px;
        border-bottom: 1px solid #f1f1f1;
    }
</style>
