<template>
    <div class="m-index">
        <div class="homepage__push homepage-push" style="margin-bottom: 1.9rem;">
            <img src="@/assets/images/m_/enterprise_banner.jpg" alt="">
            <div class="homepage__button">
                <a href="/m/enterprise">
                    <button>詳細はこちら</button>
                </a>
            </div>
        </div>
        <div class="homepage__push homepage-push" style="margin-bottom: 1.9rem;">
            <img src="@/assets/images/m_/laboratory_banner.jpg" alt="">
            <div class="homepage__button">
                <a href="/m/about">
                    <button>詳細はこちら</button>
                </a>
            </div>
        </div>
        <div class="homepage__push -brand choose">
            <div>
                <a href="https://shop.wisdomjp.co.jp/collections/my-hl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA">
                    <img src="@/assets/images/m_/banner_01_1.jpg" alt="">
                </a>
                <span>詳細はこちら</span>
            </div>
        </div>
        <div class="homepage__push -brand choose">
            <div>
                <a href="https://shop.wisdomjp.co.jp/collections/my-pl%E3%82%A8%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B9%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA">
                    <img src="@/assets/images/m_/banner_02_1.jpg" alt="">
                </a>
                <span>詳細はこちら</span>
            </div>
        </div>
        <div class="homepage__push -brand website">
            <div>
                <a href="https://memoryyoung.com/">
                    <img src="@/assets/images/m_/my.jpg" alt="">
                </a>
                <span>詳細はこちら</span>
            </div>
        </div>
        <div class="homepage__push -brand website" style="margin-bottom: 20px">
            <div>
                <a href="https://guexy.com/">
                    <img src="@/assets/images/m_/guexy.jpg" alt="">
                </a>
                <span>詳細はこちら</span>
            </div>
        </div>
    </div>

</template>

<script>
export default {
}
</script>

<style scoped>
.homepage__push{
    width: 100%;
    position: relative;
}
.-brand{
    width: calc(50% - 30px);
    display: inline-block;
    padding-left: 20px;
}
.-brand>div{
    font-size: 0;
    width: 100%;
}
.-brand>div img{
    width: 100%;
    height: auto;
}
.-brand>div>span{
    font-size: 12px;
    display: block;
    text-align: center;
    height: 35px;
    line-height: 35px;
}
.homepage-push img{
    width: 100vw;
    height: auto;
    display: block;
}
.homepage__button{
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5rem;
}
.homepage__button>a{
    height: 1.9rem;
    display: inline-block;
}
.homepage__button button{
    display: block;
    width: 8.8rem;
    height: 1.9rem;
    font-size: 12px;
    color: #8f9192;
    background: #ffffff;
    border: 0;
}
</style>
