<template>
    <div class="">
        <div class="homepage__push homepage-push">
            <img src="@/assets/images/m_/brief_banner.jpg" alt="">
        </div>
        <div class="content">
            <div class="homepage__title">
                会社プロフィール
            </div>
            <ul class="homepage__ul">
                <li class="homepage__ul__item">
                    <h2 class="title">商号:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-03.png" alt=""><span>株式会社ウィズダム</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">設立:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-04.png" alt=""><span>平成15年4月28日</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">資本金:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-05.png" alt=""><span>6,400万円</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">代表取締役:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-06.png" alt=""><span>杼木浩</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">所在地:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-07.png" alt=""><span>東京都新宿区西早稲田一丁目9番35-302号</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">連絡先:</h2>
                    <div class="item__content">
                        <div class="box">
                            <img class="icon" src="@/assets/images/icon/W2-08.png" alt=""><span>TEL：03-6820-0668 / FAX：03-6820-0668</span>
                        </div>
                        <div class="box">
                            <img class="icon" src="@/assets/images/icon/W2-13.png" alt=""><span>info@wisdomjp.co.jp</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">所属団体:</h2>
                    <div class="item__content">
                        <div class="box" style="bottom: 5px;">
                            <img class="icon" src="@/assets/images/icon/W2-11.png" alt=""><span>日本化粧品工業連合会正会員</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">許可:</h2>
                    <div class="item__content">
                        <div class="box">
                            <img class="icon" src="@/assets/images/icon/W2-10.png" alt=""><span>化粧品製造販売業許可</span>
                        </div>
                        <div class="box">
                            <img class="icon" src="@/assets/images/icon/W2-10.png" alt=""><span>医薬部外品製造販売業許可</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">事業内容:</h2>
                    <div class="item__content">
                        <div class="box" style="text-align: left;padding-left: 10px">
                            <img class="icon" src="@/assets/images/icon/W2-09.png" alt=""><span>基礎化粧品、医薬部外品</span>
                        </div>
                        <div class="box" style="text-align: left;padding-left: 10px">
                            <img class="icon" src="@/assets/images/icon/W2-09.png" alt=""><span>日用品雑貨、美容器等の商品企画開発</span>
                        </div>
                        <div class="box" style="text-align: left;padding-left: 10px">
                            <img class="icon" src="@/assets/images/icon/W2-09.png" alt=""><span>OEM受託製造、販売各種、及び上記に関わるコンサルティング</span>
                        </div>
                    </div>
                </li>
                <li class="homepage__ul__item">
                    <h2 class="title">取引銀行:</h2>
                    <div class="item__content">
                        <div class="box">
                            <img class="icon" src="@/assets/images/icon/W2-12.png" alt=""><span>みずほ銀行</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="banner map" style="margin-top: 2rem;">
                <img :src="require('@/assets/images/' + lang + '_map.jpg')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import {getCookieVal} from "@/assets/js/lang";
export default {
    data(){
        return{
            screenWidth:null,
            lang:'jap'
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/brief'){
                    this.$router.replace('/m/brief')
                }
            }else{
                if(this.$route.path !== '/brief'){
                    this.$router.replace('/brief')
                }
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    created(){
        this.lang = getCookieVal('lang')
    }
}
</script>
<style scoped>
.homepage__push{
    width: 100%;
    font-size: 0;
}
.homepage__push img{
    width: 100%;
}
.content{
    padding: 1.5rem;
    background: #f5f4f2;
}
.homepage__title{
    font-family: "Motoya,Meiryo";
    font-size: 1.1rem;
    font-weight: 600;
    color: #231816;
    margin-left: 8px;
    margin-bottom: 1.2rem;
}
.homepage__ul li{
    background: #ffffff;
    padding: 15px 5%;
    margin: 0 1px;
    margin-bottom: 7px;
}
.homepage__ul .icon{
    position: relative;
    top: 6px;
    width: 20px;
    height: auto;
}
.homepage__ul .title{
    font-family: "Motoya,Meiryo";
    margin-bottom: 12px;
}
.item__content .box{
    color: #5a504e;
    border: 1px solid #dadada;
    padding-bottom: 11px;
    padding-top: 4px;
    text-align: center;
    margin-bottom: 5px;
    font-size: 12px;
}
</style>
