import $ from './jquery.min'
let dict = {};
$(function(){
    registerWords();
    let cookies = getCookieVal("lang")
    let locationHost = window.location.host
    if(cookies == null){
        switch (locationHost) {
            case 'wisdomjp.co.jp':
                cookies = 'jap'
                break;
            case 'wisdomjp.com':
                cookies = 'en'
                break;
            default:
                cookies = 'zh'
        }
        setLanguage(cookies);
    }
    $('.brand_li').hover(function(){
        $('.brand').css('display','block')
    },function(){
        $('.brand').css('display','none')
    })

    $('.-lang').hover(function(){
        $('.hdr_sedsp').css('display','block')
    },function(){
        $('.hdr_sedsp').css('display','none')
    })
})

export function getCookieVal(name) {
    var items = document.cookie.split(";");
    for (let i in items) {
        let cookie = $.trim(items[i]);
        let eqIdx = cookie.indexOf("=");
        let key = cookie.substring(0, eqIdx);
        if (name == $.trim(key)) {
            return $.trim(cookie.substring(eqIdx+1));
        }
    }
    return null;
}

export function setLanguage(lang) {
    setCookie("lang=" + lang + "; path=/;");
    translate();
}

function setCookie(cookie) {
    document.cookie = cookie;
}

export function translate() {
    loadDict();
    /* 顶部 */
    let lang = getCookieVal('lang');
    let header = __tr('header');
    $('.header-main-nav__item_span').each(function(i){
        $(this).text(header.nav[i])
    })
    $('.header__utility-items .-lang a span').text(header.lang)

    /* 底部 */
    let footer = __tr('footer');
    $('.lv-list .lv-list_li a').each(function(i){
        $(this).text(footer.list[i])
    })

    /* index */
    let index = __tr('index');
    let banners = {
        zh:[require('@/assets/images/zh_pl_banner.jpg'),require('@/assets/images/zh_hl_banner.jpg')],
        trad:[require('@/assets/images/trad_pl_banner.jpg'),require('@/assets/images/trad_hl_banner.jpg')],
        jap:[require('@/assets/images/jap_pl_banner.jpg'),require('@/assets/images/jap_hl_banner.jpg')],
        en:[require('@/assets/images/en_pl_banner.jpg'),require('@/assets/images/en_hl_banner.jpg')],
    }
    $('.first img').attr('src',banners[lang][0]);
    $('.second img').attr('src',banners[lang][1]);

    $('.index_banner .banner_jump_link').text(index.button[0])
    $('.content-push-list li span').text(index.button[1])

    /* OME */
    let about = __tr('about');
    $('.develop_main_h2').html(about.title[0])
    $('.develop_main_p1').text(about.title[1])
    $('.develop_main_p2').text(about.title[2])
    $('.develop_main_p3').html(about.background)
    $('.procedure h2').text(about.omeTitle)
    for(let i = 0;i < about.ome.length;i++){
        $('.procedure_main ul li:eq('+i+') span:eq(0)').text(about.ome[i].title)
        $('.procedure_main ul li:eq('+i+') span:eq(1)').text(about.ome[i].content)
    }

    /* 企业 */
    let brief = __tr("brief");
    $('.brief_title').text(brief.title)

    for(let i = 0;i < brief.briefList.length;i++){
        $('.brief_main ul li:eq('+i+') .title').text(brief.briefList[i].title)
        for(let j = 0;j < brief.briefList[i].content.length;j++){
            $('.brief_main ul li:eq('+i+') .box:eq('+j+') span').text(brief.briefList[i].content[j])
        }

    }

    /* 隐私 */
    let legalPrivacy = __tr("legalPrivacy");
    let legalHtml = '';
    let legalHtmlUl = '';
    $('.tabSectionTitle').text(legalPrivacy.title)
    $('.tabSummary p').text(legalPrivacy.introduction)
    $.each(legalPrivacy.content,function(key,value){
        legalHtml += '<div id="tab0-Chapter'+key+'" class="tabChapterContainer">\n' +
            '                    <div id="tab'+key+'-ChapterTitle'+key+'" class="tabChapterTitleContainer">\n' +
            '                        <div class="tabChapterTitle onlyML">\n' +
            '                            <h3>'+value.title+'</h3>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                    <div id="tab'+key+'-ChapterDesc'+key+'" class="tabChapterDescription exp_content legalPrivacyPanels legalPrivacyChapter-collapse">\n' +
            '                        <p>'+value.introduction+'</p>\n' +
            '                    </div>\n' +
            '                </div>'
        legalHtmlUl += '<li>\n' +
            '<a href="#tab0-Chapter'+key+'" class="tabChapterAnchor smoothScrollAnchor">'+value.title+'</a>\n' +
            '</li>'
    })
    $('.privacy').html(legalHtml)
    $('.rightColumnWrapper ul').html(legalHtmlUl)

    /*关于我们*/
    let contact = __tr("contact");
    $('.modal__content h3').text(contact.title[0])
    $('.message h3').text(contact.title[1])
    $('.modal__content__li').each(function (i){
        $(this).find('span').text(contact.contactUs[i].title)
        $(this).find('.modal__content__item').text(contact.contactUs[i].center)
    })
    $('.from label').each(function (i){
        $(this).html(contact.message[i])
    })
    $('.from button').html(contact.button)

    // 企业理念
    $('.enterprise img').attr('src',require('@/assets/images/enterprise_'+ lang +'.jpg'))


    $('.map img').attr('src',require('@/assets/images/'+ lang +'_map.jpg'))

    let mp4 = require('@/assets/images/' + lang + '_enterprise.mp4');
    let src = $('<source class="enterprise_mp4" src="'+ mp4 +'" type="video/mp4">');
    let enterprise_video = $('.enterprise_video')
    if(enterprise_video.length > 0){
        enterprise_video.empty()
        enterprise_video.append(src)
        enterprise_video[0].load()
    }
}

export function __tr(src) {
    return (dict[src] || src);
}

function loadDict() {
    let lang = (getCookieVal("lang") || "jap");
    dict = require('./'+ lang +'.json')
}


function registerWords() {
    $("[lang]").each(function() {
        switch (this.tagName.toLowerCase()) {
            case "input":
                $(this).attr("lang", $(this).val());
                break;
            default:
                $(this).attr("lang", $(this).text());
        }
    });
}













