import Vue from 'vue'
import VueRouter from 'vue-router'
import news from '../views/news'
import enterprise from '../views/enterprise'
import Index from '../views/Index'
import LegalPrivacy from '../views/legal-privacy'
import MLegalPrivacy from '../views/h5/legal-privacy'
import MIndex from '../views/h5/index'
import MDevelop from '../views/h5/develop'
import develop from '../views/develop'
import MBrief from '../views/h5/brief'
import MNews from '../views/h5/news'
import MEnterprise from '../views/h5/enterprise'
import brief from '../views/brief'
import contact from '../views/contact'
import product from '../views/product'
Vue.use(VueRouter)

// 简单的映射配置
const routes = [{
    path: '/',
    // 路由重定向
    redirect: '/index',
},
    {
        path: '/index',
        component: Index,
        meta: {
            title: '首页'
        }
    }, {
        path: '/news',
        component: news,
        meta: {
            title: '新闻'
        }
    },{
        path: '/about',
        component: develop,
        meta: {
            title: 'OME流程'
        }
    },{
        path: '/brief',
        component: brief,
        meta: {
            title: '企业简介'
        }
    },{
        path: '/product',
        component: product,
        meta: {
            title: '产品分类'
        }
    }, {
        path: '/contact',
        component: contact,
        meta: {
            title: '联系我们'
        }
    }, {
        path: '/m/index',
        component: MIndex,
        meta: {
            title: '首页'
        }
    }, {
        path: '/m/about',
        component: MDevelop,
        meta: {
            title: '流程'
        }
    }, {
        path: '/m/brief',
        component: MBrief,
        meta: {
            title: '流程'
        }
    }, {
        path: '/m/news',
        component: MNews,
        meta: {
            title: '新闻'
        }
    }, {
        path: '/legal-privacy',
        component: LegalPrivacy,
        meta: {
            title: '隐私条款'
        }
    }, {
        path: '/m/legal-privacy',
        component: MLegalPrivacy,
        meta: {
            title: '隐私条款'
        }
    }, {
        path: '/enterprise',
        component: enterprise,
        meta: {
            title: '企业简介'
        }
    }, {
        path: '/m/enterprise',
        component: MEnterprise,
        meta: {
            title: '企业简介'
        }
    }
]

// 创建路由实例
const router = new VueRouter({
    // 路由和组件之间的映射关系
    // routers: routers的简写
    routes,
    // 使用history模式,也可以使用hash模式
    mode: 'history',
});

// 3.将router对象导出
export default router;
