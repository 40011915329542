<template>
    <div class="contact">
        <img src="@/assets/images/brief_banner.jpg" alt="">
        <div class="modal__content">
            <h3>联系我们</h3>
            <div class="modal__content__li">
                <span>住所</span>
                <div class="modal__content__item">日本東京都新宿区西早稲田一丁目9番35-302号</div>
            </div>
            <div class="modal__content__li">
                <span>TEL</span>
                <div class="modal__content__item">03-6820-0668</div>
            </div>
            <div class="modal__content__li">
                <span>FAX</span>
                <div class="modal__content__item">03-6820-0668</div>
            </div>
            <div class="modal__content__li">
                <span>E-MAIL</span>
                <div class="modal__content__item">info@wisdomjp.co.jp</div>
            </div>
        </div>
        <div class="message">
            <h3>留言</h3>
            <div class="from">
                <div>
                    <label>姓名<span>*</span></label>
                    <input type="text" v-model="form.user_name">
                </div>
                <div>
                    <label>联系方式</label>
                    <input type="text" v-model="form.contact">
                </div>
                <div>
                    <label>性别</label>
                    <el-select v-model="form.sex" placeholder="请选择" style="width: 100%">
                        <el-option label="Male" value="0"></el-option>
                        <el-option label="Female" value="1"></el-option>
                    </el-select>
                </div>
                <div>
                    <label>邮箱<span>*</span></label>
                    <input type="text" v-model="form.email">
                </div>
                <div>
                    <label>验证码<span>*</span></label>
                    <input type="text" v-model="form.control">
                </div>
                <div style="height: 132px;line-height: 132px;position: absolute">
                    <img :src="qrUrl" alt="" style="width: 265px;position: relative;top: 26px;" @click="handImg()">
                </div>
                <div style="width: 96%">
                    <label>留言内容<span>*</span></label>
                    <textarea v-model="form.content"></textarea>
                </div>
                <button class="contact_but" @click="onThisFooter()">立即发送</button>
            </div>
        </div>
        <div id="container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1926.299172999875!2d139.71929484556725!3d35.71057063619641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1szh-CN!2shk!4v1667358174427!5m2!1szh-CN!2shk" style="border:0;width: 100%;height: 100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="banner map" style="max-width: 1090px;margin: 3rem auto 0;padding-bottom: 4rem" >
            <img :src="require('@/assets/images/' + lang + '_map.jpg')" alt="">
        </div>
    </div>
</template>

<script>
import {__tr, getCookieVal} from "@/assets/js/lang"
export default {
    data() {
        return {
            form:{
                control:'',
                contact:'',
                email:'',
                user_name:'',
                content:'',
                sex:'0',
                uuid:'',
            },
            qrUrl:'',
            screenWidth:null,
            lang:'zh'
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        onThisFooter(){
            let lang = (getCookieVal("lang") || "jap");
            this.form.lang = lang
            this.$http.post('/super/admin/leave.php?act=add',this.form).then(res => {
                let contact = __tr("contact");
                if(res.data.code === 0){
                    return this.$alert(contact.tips, {
                        confirmButtonText: contact.but,
                        callback:action => {
                            this.form = {
                                control:'',
                                contact:'',
                                email:'',
                                user_name:'',
                                content:'',
                                sex:'0',
                            }
                        }
                    });
                }else if(res.data.code === 401){
                    return this.$message({
                        type: 'info',
                        message: contact.control_error
                    });
                }else{
                    return this.$message({
                        type: 'info',
                        message: res.data.message
                    });
                }
            })
        },
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/contact'){
                    this.$router.replace('/m/contact')
                }
            }else{
                if(this.$route.path !== '/contact'){
                    this.$router.replace('/contact')
                }
            }
        },
        handImg(){
            this.getRamNumber()
            let that = this
            this.$http({
                url:'/super/admin/control.php?sess_id='+this.form.uuid,
                responseType:'blob',
                method:"get"
            }).then(res=>{
                that.qrUrl = window.URL.createObjectURL(res.data);
            })
        },
        getRamNumber(){
            let result='';
            for(let i=0;i<26;i++){
                result += Math.floor(Math.random()*26).toString(26);
            }
            this.form.uuid = result;
        },
    },
    mounted() {
        this.lang = getCookieVal('lang');
        this.handImg()
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style>
.contact{
    margin-top: 116px;
}
#container{
    width: 1090px;
    height: 428px;
    margin: 20px auto;
    box-shadow: 10px 10px 5px #888888;
}
.info {
    z-index: 999;
    width: auto;
    padding: 10px;
    margin-left: 10px;
    position: fixed;
    top: 10px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 14px;
    color: #666;
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
}
ul li {
    list-style: none;
}
.btn-wrap {
    z-index: 999;
    position: fixed;
    width: 340px;
    bottom: 30px;
    left: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(265, 265, 265, 0.9);
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
}
.btn {
    width: 100px;
    height: 30px;
    float: left;
    background-color: #fff;
    color: rgba(27, 142, 236, 1);
    font-size: 14px;
    border:1px solid rgba(27, 142, 236, 1);
    border-radius: 5px;
    margin: 0 5px 8px;
    text-align: center;
    line-height: 30px;
}
.btn:hover {
    background-color: rgba(27, 142, 236, 0.8);
    color: #fff;
    cursor: pointer;
}
.message{
    max-width: 1126px;
    margin: 0 auto;
}
.modal__content{
    padding: 0 !important;
    max-width: 1126px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 0;
}
.modal__content__li{
    display: inline-block;
    width: 49%;
}
.modal__content__li span{
    margin-left: 20px;
}
.modal__content .modal__content__item{
    margin-left: 20px;
    padding: 20px;
    background: #000000;
    color: #eae8e4;
    margin-bottom: 2rem;
    box-shadow: 10px 10px 4px #888888;
}
.message h3,.modal__content h3{
    margin-bottom: 30px;
    margin-left: 20px;
    font-size: 22px;
}
.from{
    width: calc(100% - 24px);
    padding: 0 12px;
}
.from div{
    display: inline-block;
    width: 49%;
    margin: 0 4px;
}
.from div label{
    font-size: 18px;
    color: #666;
}
.from div input{
    width: 94%;
    height: 50px;
    background: #f5f7f9;
    border: 0 solid #e6eaf0;
    border-radius: 0;
    padding: 0 15px;
    margin-top: 20px;
    transition: all 0.15s;
    font-size: 16px;
    margin-bottom: 35px;
    outline: none;
}
.from div input:focus,textarea:focus {
    box-shadow: 0 0 0 0.2rem rgb(63 61 134 / 25%);
}
textarea{
    margin-top: 20px;
    display: block;
    background: #f5f7f9;
    height: 170px;
    padding: 15px;
    font-size: 14px;
    border: 0 solid #e6eaf0;
    width: 100%;
    outline: none;
}
.contact_but{
    background: #3f3d86;
    color: #fff;
    font-size: 16px;
    width: 7.813vw;
    min-width: 130px;
    height: 3.604vw;
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    border: 0;
}
.el-input__inner{
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0 solid #e6eaf0;
    background: #f5f7f9;
}
.el-select>.el-input{
    width: 100%;
}
.el-select{
    width: 100% !important;
}
.el-input__prefix, .el-input__suffix{
    top: -7px !important;
    right: 17px !important;
}
.el-input--suffix .el-input__inner{
    width: 98%;
}
</style>
