<template>
    <div class="exp_content" id="main" style="margin-top: 116px;">
        <div class="column leftColumn">
            <div class="leftColumnWrapper">
                <div class="tabSectionHeader">
                    <h2 class="tabSectionTitle">
                        <!--lang.js-->
                    </h2>
                </div>
                <div class="tabSummary">
                    <p><!--lang.js--></p>
                </div>
                <div class="privacy">
                    <!--lang.js-->
                </div>
            </div>
        </div>
        <div class="column rightColumn">
            <div class="rightColumnWrapper" role="navigation">
                <div class="preliminaries"></div>
                <ul class="rightColumnWrapper_ul">
                    <!--lang.js-->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "legal-privacy",
    data(){
        return{
            screenWidth:null
        }
    },
    watch: {
        screenWidth (val, oldVal){
            if(this.$route.path === '/index'){
                this.replaceIndex(val)
            }
            if(this.$route.path === '/m/index'){
                this.replaceIndex(val)
            }
        }
    },
    methods: {
        replaceIndex(val){
            if(val < 600) {
                if(this.$route.path !== '/m/legal-privacy'){
                    this.$router.replace('/m/legal-privacy')
                }
            }else{
                if(this.$route.path !== '/legal-privacy'){
                    this.$router.replace('/legal-privacy')
                }
            }
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        this.replaceIndex(this.screenWidth)
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
}
</script>

<style scoped>

</style>
