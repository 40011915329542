<template>
    <div class="news_info">
        <div class="news_xq">
            <div v-html="form.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id:this.$route.query.id,
            form:{}
        }
    },
    watch:{
        '$route.query.id'(news){
            this.id = news
            this.fetchData()
        }
    },
    methods: {
        fetchData(){
            let params = {
                id:this.id
            }
            this.$http.post('/super/admin/news.php?act=detail',params).then(res=>{
                this.form = res.data.data
            })
        }
    },
    created(){
        this.fetchData()
    },
}
</script>

<style>
.news_info{
    padding-top: 56px;
}
img{
    width: 100%;
    height: auto;
}
.news_info p{
    margin: 0 !important;
    padding: 14px  !important;
}
video{
    width: 100%;
}
</style>
