<template>
  <div class="modal__contents">
    <h1 class="modal__title">
      <span>MY アカウント</span>
    </h1>
    <button class="modal__close icon-button -light" @click="closeLogin">
      <i class="el-icon-close"></i>
    </button>
    <div class="modal__content">
      <div class="header__login">
        <div class="login-form">
          <section class="login-form__sign-in">
            <h2>アカウント情報の入力</h2>
            <div class="login-form__form">
              <div class="lv-login-form__container">
                <div class="social-login__items list">
                  <label class="field-label">メールアドレス*</label>
                  <el-input v-model="form.account"></el-input>
                </div>
                <div class="login-form__social-title">
                  <div class="lv-input-password">
                    <label class="field-label input-password__label">パスワード*</label>
                    <el-input v-model="form.password" show-password></el-input>
                    <span class="input-password__forgot-pwd-link">
                              パスワードをお忘れですか？
                            </span>
                  </div>
                </div>
              </div>
              <el-button class="login-form__button">ログイン</el-button>
            </div>
          </section>
        </div>
        <div class="header__login-separator -modal-full-size"></div>
        <div class="create-account">
          <h2>アカウント新規登録</h2>
          <p>
            「MY WISDOM」に登録すると、ウィッシュリスト機能やショッピングなど、公式サイトのご利用がさらに便利に。
          </p>
          <el-button class="login-form__button">アカウントを作成</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form:{
        account:'',
        password:''
      },
    }
  },
  methods: {
    closeLogin(){
      this.$parent.closeLogin();
    }
  }
}
</script>

<style scoped>

</style>
